import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../utils/htmlSerializer"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Button from "../components/shared/button"
import Banner from "../components/widgets/banner"
import PersonList from "../components/widgets/personList"
import CartoonGroup from "../components/widgets/cartoonGroup"

import Reveal from "react-reveal/Reveal"

// import TeamList from "../components/widgets/teamList";

const AboutPage = ({ data }) => {
  const doc = data.prismic.allAbouts.edges.slice(0, 1).pop()
  // const founders = data.prismic.allOur_teams.edges.find(
  //   list => list.node.list === "Founder"
  // )
  const board = data.prismic.allOur_teams.edges.find(
    list => list.node.list === "Board"
  )

  return (
    <Layout pageTitle={doc.node.page_heading[0].text}>
      <SEO
        title={
          doc.node.meta_title
            ? doc.node.meta_title
            : doc.node.page_heading[0].text
        }
        image={doc.node.meta_image}
        description={doc.node.meta_description}
      />

      <Banner content={doc.node.banner_title} color="cyan" />

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell medium-9`}>
              <p className={`u-text-large`}>{doc.node.intro_text}</p>
            </div>
          </div>
        </section>
      </Reveal>

      <CartoonGroup />

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <RichText
                render={doc.node.main_content}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </section>
      </Reveal>

      {/* <Reveal effect="fadeInUp" fraction={0.05}>
        <section
          id={founders.node.group_name.replace(/\s+/g, "-").toLowerCase()}
          className={`grid-container`}
        >
          <div className={`grid-x`}>
            <div className={`cell`}>
              <h2 className={`u-text-large`}>{founders.node.group_name}</h2>
            </div>
            <div className={`cell`}>
              <PersonList list={founders.node.team} />
            </div>
          </div>
        </section>
      </Reveal> */}

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section
          id={board.node.group_name.replace(/\s+/g, "-").toLowerCase()}
          className={`grid-container`}
        >
          <div className={`grid-x`}>
            <div className={`cell`}>
              <h2 className={`u-text-large`}>{board.node.group_name}</h2>
            </div>
            <div className={`cell`}>
              <PersonList list={board.node.team} />
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <RichText
                render={doc.node.secondary_content}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </section>
      </Reveal>

      <aside className={`c-aside grid-container`}>
        <div className={`c-aside__content grid-x`}>
          <Reveal effect="fadeInUp" fraction={0.05}>
            <div className={`cell`}>
              <RichText
                render={doc.node.bottom_cta_heading}
                htmlSerializer={htmlSerializer}
              />

              <Button
                text={doc.node.bottom_cta_text}
                link={doc.node.bottom_cta_link}
                emoji={doc.node.bottom_cta_emoji}
                emojiLabel={doc.node.bottom_cta_emoji_label}
              />
            </div>
          </Reveal>
        </div>
      </aside>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    prismic {
      allOur_teams {
        edges {
          node {
            ...teamFields
          }
        }
      }
      allAbouts {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            page_title
            page_heading
            banner_title
            intro_text
            main_content
            secondary_content
            bottom_cta_heading
            bottom_cta_text
            bottom_cta_link {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            bottom_cta_emoji_label
            bottom_cta_emoji
          }
        }
      }
    }
  }
`

export default AboutPage
